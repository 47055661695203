import React from "react";
import { Link } from "react-router-dom";
import $11 from '../../images/11.webp';

class ListClubs extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">CLUBS</h1>
        </div>
        <div className="container page-top-2">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="FL page-heading">CLUBS</h1>
            </div>
            <div className="col-sm-4">
              {" "}
              <Link className="btn btn-primary FR margin6" to={"/add/club"}>
                Add A CLUB
              </Link>{" "}
              <Link className="btn btn-primary FR margin6" to={"/delete/club"}>
                Delete CLUB
              </Link>
            </div>
          </div>
          <div className="row padding30">
            <div className="col-sm-3">
              <div className="card bdr-radius">
                <img src={$11} className="img-fluid" alt="" />
              </div>
              <div className="club-title">
              <Link to={`/viewClub`}>CLUB - 1</Link>
              </div>
            </div>
          </div>
          <div className="row">
            <nav
              aria-label="..."
              style={{
                margin: "0 auto"
              }}
            >
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    <span aria-hidden="true">«</span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    1
                  </Link>
                </li>
                <li className="page-item active" aria-current="page">
                  <Link className="page-link" to={`/clubs`}>
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    6
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    ...
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    15
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    16
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={`/clubs`}>
                    <span aria-hidden="true">»</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default ListClubs;
