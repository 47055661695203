
import React from 'react';
import './App.css';
import './css/style.css';
import './css/style2.css';
import './css/carousel.css';
import 'bootstrap';
import Header from './components/static/Header';
import Home from './components/static/Home';
import Footer from './components/static/Footer';
import About from './components/static/About';
import Dashboard from './components/static/Dashboard';
import Contact from './components/static/Contact';
import ListServices from './components/static/ListServices';
import ListClubs from './components/static/ListClubs';
import ListAdvertisments from './components/static/ListAdvertisments';
import ListProducts from './components/static/ListProducts';
import AddProduct from './components/static/AddProduct';
import AddAdvertisment from './components/static/AddAdvertisement';
import AddClub from './components/static/AddClub';
import DeleteProduct from './components/static/DeleteProduct';
import DeleteAdvertisment from './components/static/DeleteAdvertisement';
import DeleteClub from './components/static/DeleteClub';
import SuperAdminLogin from './components/static/SuperAdminLogin';
import SchoolAdminLogin from './components/static/SchoolAdminLogin';
import BOLogin from './components/static/BOLogin';
import Login from './components/static/Login';
import StudentLogin from './components/static/StudentLogin';
import Register from './components/static/Register';
import Profile from './components/static/Profile';
import ManageUsers from './components/static/ManageUsers';
import ForgetPassword from './components/static/ForgetPassword';
import IndClubView from './components/static/IndClubView';
import IndAdvtView from './components/static/IndAdvtView';
import IndProductView from './components/static/IndProductView';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
function App() {
  return (<>
    <div className="App">
      <Router>
        <Header />
        <div> <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<ListServices />} />
          <Route path="/clubs" element={<ListClubs />} />
          <Route path="/advertisments" element={<ListAdvertisments />} />
          <Route path="/products" element={<ListProducts />} />
          <Route path="/add/product" element={<AddProduct />} />
          <Route path="/add/advertisment" element={<AddAdvertisment />} />
          <Route path="/add/club" element={<AddClub />} />
          <Route path="/delete/product" element={<DeleteProduct />} />
          <Route path="/delete/advertisment" element={<DeleteAdvertisment />} />
          <Route path="/delete/club" element={<DeleteClub />} />
          <Route path="/user/login" element={<Login />} />
          <Route path="/student/login" element={<StudentLogin />} />
          <Route path="/user/register" element={<Register />} />
          <Route path="/admin/login" element={<SuperAdminLogin />} />
          <Route path="/school/login" element={<SchoolAdminLogin />} />
          <Route path="/bo/login" element={<BOLogin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin/users" element={<ManageUsers />} />
          <Route path="/user/forgetpassword" element={<ForgetPassword />} />
          <Route path="/viewClub" element={<IndClubView />} />
          <Route path="/viewAdvertisement" element={<IndAdvtView />} />
          <Route path="/viewProduct" element={<IndProductView />} />
        </Routes>
        </div>
      </Router>
      <Footer />
    </div></>
  );
}

export default App;
