import React from "react";
import { Link } from "react-router-dom";
import $1 from '../../images/1.png';
import $2 from '../../images/2.png';
import $3 from '../../images/3.png';

class SuperAdminLogin extends React.Component {
  render() {
    return (
      <main role="main">
        { }
        { }
        <div className="container marketing padding30">
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Profile</h2>
              <p>As a superadmin, you can manage profile of your details.</p>
              <p>
                <Link className="btn btn-secondary" to={"/profile"}>
                  Profile »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>School Admins</h2>
              <p>
                As a superadmin, you can manage all the schhol admins of the
                schools registered with the website.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/admin/users"}>
                  Manage School Admins »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Students</h2>
              <p>
                As a superadmin, you can manage all the students registered
                belonging to all the registered colleges.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/admin/users"}>
                  Manage Students »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Business Owners</h2>
              <p>
                As a superadmin, you can manage all the business owners
                belonging to all the schools registered with the website.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/admin/users"}>
                  Manage BusinessOwners »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Reports</h2>
              <p>
                As a superadmin, you can view the report of the events happening
                in the website.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/"}>
                  Reports »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Connect</h2>
              <p>
                As a superadmin, you can connect and clear the queries of users
                associated with the website.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/clubs"}>
                  Chat »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          { }
          { }
        </div>
        { }
        { }

      </main>
    );
  }
}

export default SuperAdminLogin;
