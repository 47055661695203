import React from "react";
import 'jquery';
class Contact extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">Contact Us</h1>
        </div>
        <div className="container page-top-2">
          <div className="row">
            <div className="col-sm-6">
              <div className="card bdr-none">
                <h2 className="page-heading">Email us at:</h2>
                <p>
                  <a href="mailto://marketplace@gmail.com">
                    marketplace@gmail.com
                  </a>
                </p>
                <h2 className="page-heading">Reach out us at:</h2>
                <p className="lead">
                  University of Texas at Arlington, <br />
                  701 S Neddermann Drive, <br />
                  Arlington,Texas
                  <br /> USA, 76019{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="page-heading">
                We'd love to Hear From You, Lets Get In Touch
              </h2>
              <form action>
                <div className="form-group row">
                  <label htmlFor="name" className="col-sm-2 col-form-label">
                    Name
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      defaultValue
                      placeholder="Name"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="staticEmail"
                    className="col-sm-2 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="staticEmail"
                      defaultValue
                      placeholder="Ex: email@gmail.com"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="Phone" className="col-sm-2 col-form-label">
                    Phone
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="number"
                      className="form-control"
                      id="Phone"
                      placeholder="90000 90000"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="col-sm-2 col-form-label"
                  >
                    Message
                  </label>
                  <div className="col-sm-10">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={6}
                      placeholder="Enter your message here..."
                      required
                      defaultValue={""}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    float: "right"
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <hr className="featurette-divider" />
          <div className="row">
            <div className="col-sm-12">
              <div className="card bdr-radius">
                <iframe title="Map Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3356.3755882236983!2d-97.11738578556235!3d32.729216193729776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7d6dabc9564d%3A0x6c5cbeb084c8b76a!2sUniversity%20of%20Texas%20at%20Arlington!5e0!3m2!1sen!2sus!4v1664832847853!5m2!1sen!2sus"
                  width="100%"
                  height={450}
                  style={{
                    border: 0
                  }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default Contact;
