import React from "react";

class AddAdvertisement extends React.Component {

  render() {
    return (
      <main role="main">
        <div className="row">
          <div className="col-sm-4"> </div>
          <div className="col-sm-4">
            <div className="card-body">
              <h2 className="text-center">Add Advertisement</h2>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    aria-describedby="name"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Description"
                    aria-describedby="Description"
                    placeholder="Description"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="url">URL</label>
                  <input
                    type="url"
                    className="form-control"
                    id="url"
                    placeholder="URL"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="url">Picture</label>
                  <input
                    type="file"
                    className="form-control"
                    id="Picture"
                    placeholder="Picture"
                    required
                    accept=".jpg, .png, .gif, .jpeg, .svg, .webp"
                  />
                </div>
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-4" />
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
        { }

      </main>
    );
  }
}

export default AddAdvertisement;
