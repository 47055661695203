import React from "react";
import { Link } from "react-router-dom";

class Login extends React.Component {
    render() {
      return (
        <main role="main">

   
<div className="container page-top">
<div className="row">
  <div className="col-sm-4">&nbsp;</div>
  <div className="col-sm-4 card box-shadow">
    <div className="card-body">
    <h2 className="text-center">Login</h2>
    <p className="text-center">Sign in to Continue</p>
    <p className="text-center">Not Registered yet? <Link to={"/user/register"}>Register</Link></p>

    <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Email</label>
        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email@gmail.com" required/>
       </div>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Password</label>
        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" required/>
      </div>
      <p className="text-center">Forgot Password? <Link to={"/user/forgetpassword"}>Reset Password</Link></p>

      <div className="col-md-12 text-center">

      <button type="submit" className="btn btn-primary text-center">Login</button>
      </div>
    </form>
</div>
</div>
  <div className="col-sm-4"></div>

</div>
<hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
</div >
   
{}
</main>
);
}
}

export default Login;