import React from "react";
import { Link } from "react-router-dom";
import $11 from '../../images/11.webp';

class DeleteAdvertisement extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">SERVICES</h1>
        </div>
        <div className="container page-top-2">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="FL page-heading">ADVERTISEMENTS</h1>
            </div>
            <div className="col-sm-4">
              {" "}
              <Link
                className="btn btn-primary FR"
                to={"/delete/advertisment"}
                onclick="alert('Selected items are deleted')"
              >
                Delete
              </Link>
            </div>
          </div>
          <div className="row padding30">
            <div className="col-sm-3">
              <div className="card bdr-radius">
                <img src={$11} className="img-fluid" alt="" />
              </div>
              <div className="club-title">
                <Link to={"/delete/advertisment"}>ADV - 1</Link>
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Check me to Delete
                </label>
              </div>
            </div>

          </div>
          <div className="row">
            <nav
              aria-label="..."
              style={{
                margin: "0 auto"
              }}
            >
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    <span aria-hidden="true">«</span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    1
                  </Link>
                </li>
                <li className="page-item active" aria-current="page">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    ...
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    7
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    8
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/advertisment"}>
                    <span aria-hidden="true">»</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default DeleteAdvertisement;
