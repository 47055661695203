import React from "react";
import { Link } from "react-router-dom";
import logo from '../../images/logo.png';
class Header extends React.Component {
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <div className="container-fluid">
            <Link className="navbar-brand" to={"/"}>
              <img src={logo} height="50px" width="80px" alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav mr-auto ">
                <li className="nav-item active">
                  <Link className="nav-link active" to={"/"}>
                    HOME <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/about"}>
                    ABOUT
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to={"/services"}>
                    SERVICES
                  </Link></li>

                <li className="nav-item ">
                  <Link className="nav-link" to={"/clubs"}>
                    CLUBS
                  </Link></li>
                <li className="nav-item ">
                  <Link className="nav-link" to={"/advertisments"}>
                    ADVERTISEMENTS
                  </Link></li>
                <li className="nav-item ">
                  <Link className="nav-link" to={"/products"}>
                    PRODUCTS
                  </Link></li>

                <li className="nav-item">
                  <Link className="nav-link" to={"/contact"}>
                    CONTACT
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link" target="_blank" href="http://cxy3402.uta.cloud/blog/">BLOG</a>
                </li>
              </ul>
              <div className="form-inline my-2 my-lg-0">
                <ul className="navbar-nav mr-auto ">
                  <li className="nav-item active">
                    <Link className="nav-link" to={"/user/login"}>
                      LOGIN/REGISTER
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
