import React from "react";
import { Link } from "react-router-dom";
import $5 from '../../images/5.jpg';
class ClubView extends React.Component {
    render() {
      return (
        <main role="main">

   <div class="container-fluid inner-page"> <h1 class="banner-text">Club View</h1></div>
    <div class="container page-top-2">
       
  <div class="row padding30">
  <div class="col-sm-5">
    <img src={$5} class="img-fluid" alt=""/>
  </div>
  <div class="col-sm-7">
   
    <h1>Club Name :  <span class="color-one">Club - 1</span> </h1>
    <h2>URL for Reference : <a href="javascript:void(0)" class="color-one">Https://google.com</a> </h2>
    <h2>Description : </h2>
    <p class="color-one lead">With an objective of help students to pursue their hobbies and interests and to bring people together the student clubs are created at the Institute.</p>

    <button class="btn btn-primary"  onclick="alert('Mockup pages do not perform form actions');">JOIN CLUB</button>
  </div>

</div>
<hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />

</div>
{}
</main>
);
}
}

export default ClubView;