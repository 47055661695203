import React from "react";
import { Link } from "react-router-dom";
import $1 from '../../images/1.png';
import $2 from '../../images/2.png';
import $3 from '../../images/3.png';

class StudentLogin extends React.Component {
  render() {
    return (
      <main role="main">
        { }
        { }
        <div className="container marketing padding30">
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Products</h2>
              <p>
                As a student, you can add the products you want to sell, delete
                them. You can also buy the products posted by others.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/products"}>
                  Manage Products »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Clubs</h2>
              <p>
                As a student you can add the student clubs that you manage,
                provide an opportunities for rest of the students to join the
                club. You can delete the student clubs when required. You can
                also join the student clubs posted by others.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/clubs"}>
                  Manage Clubs »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Advertisements</h2>
              <p>
                As a student, you can add the posts or advertisements related to
                any of the student events or clubs.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/advertisments"}>
                  Manage Advertisement »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Profile</h2>
              <p>
                As a student, you can maintain your profile of details that are
                visible to everybody else when the perform the search.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/profile"}>
                  Profile »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Explore</h2>
              <p>
                As a student, you can explore your peers and look at their
                profile.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/clubs"}>
                  Explore Fellow Students »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Connect</h2>
              <p>
                As a student you can connect with the business owners and your
                peers regarding any of the queries related to the products
                posted.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/clubs"}>
                  Chat »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          { }
          { }
        </div>
        { }
        { }

      </main>
    );
  }
}

export default StudentLogin;
