import React from "react";
import $1 from '../../images/1.png';
import $2 from '../../images/2.png';
import $3 from '../../images/3.png';
import $6 from '../../images/6.jpg';
class Home extends React.Component {
  render() {
    return (
      <main role="main">
        { }
        { }
        <div className="container marketing padding30">
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Student Market Shire</h2>
              <p>
                Registered colleges can enable their students for marketing
                products, info exchange, info posting, associations with student
                clubs. Business on colleges can also grab it's enhancement
                through us
              </p>
              { }
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Students</h2>
              <p>
                Single platform for students of a college to sell,buy the
                products, post details of student clubs so that others can get
                associated with them.
              </p>
              { }
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Business</h2>
              <p>
                Business Owners can advertise and sell their products through
                our portal
              </p>
              { }
            </div>
            { }
          </div>
          { }
          { }
          <div className="row featurette">
            <div className="col-md-7">
              <h2 className="featurette-heading">
                <span className="text-muted">Join Us</span>
              </h2>
              <p className="lead">
                Join us to provide a single platform for your students to market
                their used productsto others by interacting, to explore
                different student clubs and get engaged with them, to engage
                college's business people to advertise their products and sell
                them online.
              </p>
            </div>
            <div className="col-md-5">
              { }
              <img
                src={$6}
                alt=""
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto img-bdr"
                width={500}
                height={500}
              />
            </div>
          </div>
          { }
        </div>
        { }
      </main>
    );
  }
}

export default Home;
