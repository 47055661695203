import React from "react";
import { Link } from "react-router-dom";
import $1 from '../../images/1.png';
import $2 from '../../images/2.png';
import $3 from '../../images/3.png';
class SchoolAdminLogin extends React.Component {
  render() {
    return (
      <main role="main">
        { }
        { }
        <div className="container marketing padding30">
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Profile</h2>
              <p>
                As a schholadmin, you can manage the profile of your details
                that are visible to others when they search.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/profile"}>
                  Profile »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Products</h2>
              <p>
                As a school admin, you can moderate the products added by the
                students and business owners.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/products"}>
                  Moderate Products »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Advertisements</h2>
              <p>
                As a school admin, you can moderate the advertisements posted by
                both students and business owners.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/advertisments"}>
                  Moderate Advertisements »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Students</h2>
              <p>
                As a school admin, you can manage the students belonging to your
                school.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/admin/users"}>
                  Manage Students »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Business Owners</h2>
              <p>
                As a school admin, you can manage business owners pertaining to
                your particular school.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/admin/users"}>
                  Manage Business Owners »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Reports</h2>
              <p>
                As a school admin, you can view the analysis or reports related
                to your school.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/"}>
                  Reports »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Connect</h2>
              <p>
                As a school admin, you can connect with students and business
                owners and answer to their queries.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/clubs"}>
                  Chat »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          { }
          { }
        </div>
        { }
        { }

      </main>
    );
  }
}

export default SchoolAdminLogin;
