import React from "react";
import { Link } from "react-router-dom";
import $11 from '../../images/11.webp';
import $2 from '../../images/2.png';
import $cart4 from '../../images/cart4.svg';

class Profile extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">PROFILE</h1>
        </div>
        <div className="container page-top-2">
          <div className="row padding30">
            <div className="col-sm-3">
              <img src={$2} className="rounded img-fluid" alt="..." />
              <br />
              <br />
              <h2 className="page-heading">KRISHNA</h2>
              <p className="lead">
                Student
                <br />
                90000 00000
                <br />
                <a href="mailto://mail@gmail.com">mail@gmail.com</a>
              </p>
            </div>
            <div className="col-sm-8 offset-1">
              <h2 className="page-heading">CLUB</h2>
              <div className="row">
                <div className="col-sm-3">
                  <div className="card bdr-radius">
                    <img src={$11} className="img-fluid" alt="" />
                  </div>
                  <div className="club-title">
                    <Link to={`/club/${1}`}>CLUB - 1</Link>
                  </div>
                </div>

              </div>
              <h2 className="page-heading">Advertisements</h2>
              <div className="row">
                <div className="col-sm-3">
                  <div className="card bdr-radius">
                    <img src={$11} className="img-fluid" alt="" />
                  </div>
                  <div className="club-title">
                    <Link to={`/advertisment/${1}`}>ADV - 1</Link>
                  </div>
                </div>

              </div>
              <h2 className="page-heading">Products</h2>
              <div className="row">
                <div className="col-sm-3 margin-bottom10 ">
                  <div className="card bdr-radius">
                    <img src={$11} className="img-fluid" alt="" />
                  </div>
                  <div className="club-title">
                    <Link to={`/product/${1}`}>PRODUCT - 1</Link>
                  </div>
                  <div className="price-cart">
                    <span className="prcing">199$</span>
                    <span className="carting">
                      {" "}
                      <img src={$cart4} alt="" />
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default Profile;
