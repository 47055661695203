import React from "react";
import { Link } from "react-router-dom";
import $5 from '../../images/5.jpg';
class IndProductView extends React.Component {
    render() {
      return (
        <main role="main">

   <div class="container-fluid inner-page"> <h1 class="banner-text">PRODUCT VIEW</h1></div>
    <div class="container page-top-2">
       
  <div class="row padding30">
  <div class="col-sm-5">
    <img src={$5} class="img-fluid" alt=""/>
  </div>
  <div class="col-sm-7">
   
    <h1>Product Name :  <span class="color-one">Product - 1</span> </h1>
    <h2>Product Price : <a href="javascript:void(0)" class="color-one">$119.00 $</a> </h2>
    <h2>URL for Reference : <a href="javascript:void(0)" class="color-one">Https://google.com</a> </h2>
    <h2>Description : </h2>
    <p class="color-one lead">College advertising, in particular, will have to attract the attention of young people that will need something more than just words and statistics to actually be interested. One way of doing this would be to use quizzes, games, or experiments that have the added bonus of going viral. As long as the posts are both informative and entertaining, more people will want to engage with them.</p>

    <button class="btn btn-primary"  onclick="alert('Mockup pages do not perform form actions');">BUY NOW</button>
  </div>

</div>


 
<hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />

</div>
{}
</main>
);
}
}

export default IndProductView;
